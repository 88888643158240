<template>
	<div class="layout-profile">
		<!--<button class="p-link layout-menu-button" @click="onMenuToggle">
			<span class="pi pi-bars"></span>
		</button>

		
		<div>
			<img src="assets/layout/images/profile.png" alt="" />
		</div>
		-->
		<button class="p-link layout-profile-link" @click="onClick">
			<span class="username">{{$store.state.usuario}}</span>
			<i class="bi pi-fw bi-pen"></i>
			<br><span class="username">{{$store.state.nucleo}}</span>
		</button>
        <transition name="layout-submenu-wrapper">
            <ul v-show="expanded">
                <li><button class="p-link" @click="openPerfil()"><i class="pi pi-fw pi-user"></i><span>Perfil</span></button></li>
                <li><button class="p-link" @click="openClave()"><i class="pi pi-fw pi-key"></i><span>Cambio de Clave</span></button></li>
                <li><button class="p-link" @click="Logout()"><i class="pi pi-fw pi-power-off"></i><span>Salir</span></button></li>
            </ul>
        </transition>
	</div>
	<!--<Perfil :display="displayPerfil" @aprob-perfil="onMenuToggle" @close-perfil="close"/>-->
	<Clave :display="displayClave" @aprob-clave="onMenuToggle" @close-clave="close"/>

</template>

<script>
	//import Perfil from './components/Perfil.vue';
	import Clave from './components/CambioClave.vue';
	
	export default {
		components: {
		//'Perfil': Perfil,
		'Clave': Clave
		},
		data() {
			return {
				expanded: false,
				displayPerfil: false,
				displayClave: false,
			}
		},
		methods: {
			onMenuToggle(event) {
				this.$emit('menu-toggle', event);
			},
			onClick(event){
				this.expanded = !this.expanded;
				event.preventDefault();
			},
				openPerfil() {
					this.$store.commit('Mobile');
					this.displayPerfil = true;
				},
				openClave() {
					this.$store.commit('Mobile');
					this.displayClave = true;
				},
				close() {
					this.displayPerfil = false;
					this.displayClave = false;
				},
				Logout() {
				this.$store.commit('Logout');
				this.$router.push({ path: '/' });
				}

		}
	}
</script>

<style scoped>

</style>