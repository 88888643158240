<template>
	<div>
        <Dialog header="Simulador Precios" v-model:visible="displaySimulador" @update:visible="close" :style="{width: size}" :modal="true" :maximizable="true" style="background: #0288D1;position: absolute;">
            <Toast/>
            <div class="p-fluid">
				<br>				
                <div class="p-field p-grid">
                    <div class="p-col-12 p-md-12">
                        <label>Costo $</label>
						<InputNumber v-model="costo" mode="currency" currency="USD" locale="en-US" />
                    </div>
                </div>	
                <div class="p-field p-grid">
                    <div class="p-col-12 p-md-12">
                        <label>Precio al Público $ (Sin IVA)</label>
						<InputNumber v-bind:value="((costo*(1+0.40)/(1-0.05))/(1-0.11)).toFixed(2)" mode="currency" currency="USD" locale="en-US" :disabled="true"/>
                    </div>
                </div>	
                <div class="p-field p-grid">
                    <div class="p-col-12 p-md-12">
                        <label>Precio $ (Sin IVA) - Descuento: 5%</label>
						<InputNumber v-bind:value="(0.95*(costo*(1+0.40)/(1-0.05))/(1-0.11)).toFixed(2)" mode="currency" currency="USD" locale="en-US" :disabled="true"/>
                    </div>
                </div>	
                <div class="p-field p-grid">
                    <div class="p-col-12 p-md-12">
                        <label>Precio Mínimo $ (Sin IVA) - Descuento: 10%</label>
						<InputNumber v-bind:value="(0.9*(costo*(1+0.40)/(1-0.05))/(1-0.11)).toFixed(2)" mode="currency" currency="USD" locale="en-US" :disabled="true"/>
                    </div>
                </div>	
                <div class="p-field p-grid">
                    <div class="p-col-12 p-md-12">
                        <label>Precio Mínimo $ (Sin IVA)</label>
						<InputNumber v-bind:value="((costo*(1+0.40)/(1-0.05))).toFixed(2)" mode="currency" currency="USD" locale="en-US" :disabled="true"/>
                    </div>
                </div>	
			</div>	
            <template #footer>
                <Button label="Cerrar" icon="pi pi-times" class="p-button-danger" @click="close"/>
            </template>
        </Dialog>
	</div>
</template>

<script>

export default {
    props: {
		display: {
			type: Boolean,
			default: false,
		}
	},
    data() {
        return {
			displaySimulador: false,
            size: '20vw',
            visibleFull: false,
            costo: 0,
		}
	},
    created() {      
        if (this.$store.state.mobile){this.size = '80vw';}
    },
    methods: {
        limpiar() {
            this.claveactual = null;
            this.clave = null;
            this.claveconf = null;
            this.validar = false;
            this.codigo = null;
            this.codigosend = null;
        },
        close() {
            this.displaySimulador = false;
            this.limpiar();
            this.$emit('close-simulador', event);
        },
        onMenuToggle(event) {
            this.$emit('close-clave', event);
        },	
	},
    watch: {
		display(newValue) {
			this.displaySimulador = newValue;
		}
	},


}
</script>