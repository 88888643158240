<template>
	<div class="layout-topbar">
        <button class="p-link layout-topbar-logo">
            <router-link to="/">
                <img alt="logo" src="logo.png">
            </router-link>
        </button>

		<div class="layout-topbar-icons">
			<!--
			<span class="layout-topbar-search">
				<InputText type="text" placeholder="Buscar" />
				<span class="layout-topbar-search-icon pi pi-search"></span>
			</span>
			-->
			<button class="p-link layout-menu-button" @click="onMenuToggle" v-if="this.$store.state.authenticated">
				<span class="pi pi-bars"></span>
			</button>
			<button class="p-link" @click="Carrito()" v-if="this.$store.state.authenticated">
				<span class="layout-topbar-item-text">Carrito</span>
				<span class="layout-topbar-icon pi pi-shopping-cart"></span>
				<span class="layout-topbar-badge">{{this.$store.state.carrito.length}}</span>
			</button>
			<button class="p-link" v-if="this.$store.state.authenticated">
				<span class="layout-topbar-item-text">Tasa</span>
				<span>Bs.{{moneda(this.$store.state.tasa)}}</span><br>
                <span class="ocultar">Tasa $</span>
			</button>
			<!--
			<button class="p-link" style="text-align: center;" @click="openLogin()" v-if="!this.$store.state.authenticated">
				<span class="layout-topbar-item-text">Ingresar</span>
				<span class="layout-topbar-icon pi pi-sign-in"></span><br>
                <span class="ocultar">Ingresar</span>
			</button>
			
			<button class="p-link" style="text-align: center;" @click="openRegister()" v-if="!this.$store.state.authenticated">
				<span class="layout-topbar-item-text">Registro</span>
				<span class="layout-topbar-icon bi bi-person-plus"></span><br>
                <span class="ocultar">Registro</span>
			</button>
			-->
			<button class="p-link" style="text-align: center;" @click="Logout()" v-if="this.$store.state.authenticated">
				<span class="layout-topbar-item-text">Salir</span>
				<span class="layout-topbar-icon pi pi-fw pi-power-off"></span><br>
                <span class="ocultar">Salir</span>
			</button>
			<!--
			<button class="p-link">
				<span class="layout-topbar-item-text">User</span>
				<span class="layout-topbar-icon pi pi-user-plus"></span>
			</button>
			-->
		</div>
	</div>
	<Login :display="!$store.state.authenticated" @aprob-login="onMenuToggle" @close-login="close"/>
    <Register :display="displayRegister" @close-register="close"/>


</template>

<script>
import Login from './components/Login.vue';
import Register from './components/Register.vue';

export default {
	components: {
       'Login': Login,
        'Register': Register
    },
    data() {
        return {
			displayLogin: false,
            displayRegister: false,
		}
	},
    methods: {
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
        openLogin() {
            this.$store.commit('Mobile');
            this.displayRegister = false;
            this.displayLogin = true;
        },
        openRegister() {
            this.$store.commit('Mobile');
            this.displayLogin = false;
            this.displayRegister = true;
        },
        close() {
            this.displayLogin = false;
            this.displayRegister = false;
            this.limpiar();
        },
        Carrito() {
            this.$router.push({ path: '/carrito' });
        },
        Logout() {
            this.$store.commit('Logout');
            this.onMenuToggle();
            this.$router.push({ path: '/' });
        },
			moneda(bs) {
				return bs.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			},
    }
}
</script>