import { createStore } from 'vuex';
import API from "../service/API";
import router from '../router.js';

export default createStore({
  state: {
    display: {
      login: true,
      register: false
    },
    conexion: true,
    fecha: null,
    loading: false,
    authenticated: false,
    valido: false,
    url: "./api/",
    url_api: "http://frenosvictoria.com.ve/api/",
    correo: null,
    usuario: null,
    nombre: null,
    apellido: null,
    empresa: null,
    rol: 0,
    tasa: 0,
    verificacion: false,
    ubicacion: {nombre: 'Caracas', code: 1},
    ubicaciones: [
      {nombre: 'Caracas', code: 1},
      {nombre: 'Barcelona', code: 2},
    ],
    estatus: false,
    mobile: false,
    error: null,
    admin: false,
    paginas: [],
    categoria: [],
    carrito: [],
    subcategoria: [
      {nombre: 'Búsqueda Dinámica', icon: 'bi bi-binoculars',to: '/dinamica',id: ''},
      {nombre: 'Catálogo', icon: 'bi bi-list-stars',items: []}, 
    ],
    menu : [
      {label: 'Empresa', icon: 'bi bi-globe', to: '/empresa'},
      {label: 'Forma de Pago', icon: 'bi bi-credit-card', to: '/formapago'},
      {label: 'Atención al Cliente', icon: 'bi bi-whatsapp', to: '/atencion'},
    ],
    productos: [],
    menuser: [],
    menuadmin: [],
    menu_ini: [],
    seleccion: {
      caso: null,
      id: 0
    },
    resumen: {},
    pago: {
      display: false,
      deuda: 0,
      deudabs: 0,
      datos: [],
      monto: 0,
      montobs: 0,
      tasa: 0,
      
    },
    simulador: false,

  },
  mutations: {
    Loading(){
      this.state.loading = false;	
    },
    Estatus(){
      this.state.loading = true;	
      const Consulta = new API('Productos');
      Consulta.Ini('Informacion').then(response => {
        //this.state.error = response;
        this.state.estatus = response.estatus;
        this.state.fecha = response.fecha;
        this.state.categoria = response.consult.categoria;
        this.state.subcategoria[1].items = response.consult.subcategoria;
        this.state.prodctcarousel = response.consult.prodctcarousel;
        this.state.marcascarousel = response.consult.marcascarousel;
        this.state.vehiculos = response.consult.vehiculos;
        if (localStorage.getItem('Carrito') && !this.state.authenticated){
          this.state.carrito = JSON.parse(localStorage.getItem('Carrito'));
        }
        this.state.loading = false;	     
      });
    },
    Mobile(){
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        && screen.width<1024) {
        this.state.mobile = true;
      } else {
        this.state.mobile = false;
      }    
    },
    Tasa(){    
      fetch("https://s3.amazonaws.com/dolartoday/data.json")
        .then(r => r.json())
        .then(response => {
          this.state.tasa = parseFloat(response.USD.sicad2).toFixed(2);
      });
    },
    Informacion(){
        const Consulta = new API('Productos');
        Consulta.Ini('Informacion').then(response => {
          //this.state.error = response;
          this.state.categoria = response.consult.categoria;
          this.state.subcategoria[1].items = response.consult.subcategoria;
          this.state.prodctcarousel = response.consult.prodctcarousel;
          this.state.marcascarousel = response.consult.marcascarousel;
          this.state.vehiculos = response.consult.vehiculos;
          this.state.loading = false;
        });  
        if (localStorage.getItem('Carrito') && !this.state.authenticated){
          this.state.carrito = JSON.parse(localStorage.getItem('Carrito'));
        }
     
    },
    Login(state,valores){
      this.state.display.login = false;
      this.state.authenticated = true;	
      localStorage.setItem('Token', valores.token);
      this.state.usuario = valores.nombre+' '+valores.apellido;   
      this.state.nombre = valores.nombre;        
      this.state.apellido = valores.apellido;   
      this.state.empresa = valores.empresa;
      this.state.correo = valores.correo;
      this.state.valido = valores.valido;
      this.state.menu_ini = this.state.menu;
      this.state.menu = valores.menu;
      this.state.admin = valores.admin;

      if (valores.admin) {
        this.state.menuadmin = valores.menuadmin;
        this.state.menuadmin.forEach(element => {
            this.state.paginas.push(element.to);
        });  
      }
      this.state.menu.forEach(element => {
        element.items.forEach(element => {
          this.state.paginas.push(element.to);
        }); 
      });  
      this.state.menu.push({
          label: 'Información General', icon: 'bi bi-info-circle', items: [
            {label: 'Empresa', icon: 'bi bi-globe', to: '/empresa'},
            {label: 'Servicios', icon: 'bi bi-shield-check', to: '/servicios'},
            {label: 'Forma de Pago', icon: 'bi bi-credit-card', to: '/formapago'},
          ]
      });
      this.state.menu.push({label: 'Atención al Cliente', icon: 'bi bi-whatsapp', to: '/atencion'});
      if(this.state.paginas.includes('/dashboard')){
        router.push({ path: '/dashboard' });
      } 
      if(this.state.paginas.includes('/pedidos')){
        this.state.simulador = true;
      } else {this.state.simulador = false;}

    },
    Validar(state,pagina){
      this.state.insert = false;
      this.state.edit = false;
      this.state.delete = false;
      if(!this.state.paginas.includes(pagina.path)){
        router.push({ path: '/' });
      } 
    },
    Seleccion(state,valores){
      this.state.seleccion.caso = valores.caso;
      this.state.seleccion.id = valores.id;
    },
    Imprimir(state,datos){
      this.state.imprimir.datos = datos;
      this.state.imprimir.display = true;
    },
    AgreCarrito(state,producto){
      if(!this.state.carrito.includes(producto)){
        this.state.carrito.push(producto);
      }    
      if (this.state.authenticated) {
        localStorage.setItem('Carrito',JSON.stringify(this.state.carrito));
      }
    },
    DelCarrito(state,producto){
      this.state.carrito = this.state.carrito.filter(val => val.id !== producto.id);
      if (this.state.authenticated) {
        localStorage.setItem('Carrito',JSON.stringify(this.state.carrito));
      }
    },
    ActCarrito(){
      if (this.state.authenticated) {
        localStorage.setItem('Carrito',JSON.stringify(this.state.carrito));
      }
    },
    LimpiarPagos() {
      this.state.pago = {
        display: false,
        deuda: 0,
        datos: [],
        monto: 0,
        montobs: 0,
        tasa: 0,
      };
    },
 
    Logout(){
      const Consulta = new API('Seguridad');
      Consulta.Ini('Exit');
      this.state.loading = false;
      localStorage.removeItem('Token');
      this.state.authenticated = false;
      this.state.display.login = true;
      this.state.usuario = null;   
      this.state.correo = null;
      this.state.valido = false;
      this.state.admin = false;
      this.state.paginas = [];
      this.state.menu = this.state.menu_ini;
      this.state.menuadmin = [];
      this.state.simulador = false;
    }
  },
  actions: {
  },
  modules: {
  }
})
