<template>
	<div class="layout-menu-container">
		<Message severity="warn" v-if="!$store.state.conexion">Error de Conexión</Message>
		<AppSubmenu :items="basico" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" />
		<AppProductos :items="$store.state.subcategoria" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" />
		<AppSubmenu :items="model" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" />
	</div>
</template>

<script>
import AppProductos from './AppProductos';
import AppSubmenu from './AppSubmenu';

export default {
	data() {
		return {
			basico: [{label: 'Inicio', icon: 'bi bi-house-fill', to: '/'}],
		}
	},       
	props: {
		model: Array
	},
    methods: {
        onMenuItemClick(event) {
            this.$emit('menuitem-click', event);
        }
    },
	components: {
		'AppProductos': AppProductos,
		'AppSubmenu': AppSubmenu,
	}
}
</script>

<style scoped>

</style>