<template>
	<div>
        <Dialog style="background: #727376;" header="Registrarse" v-model:visible="displayRegister" @update:visible="close" :style="{width: size}" :modal="true" :maximizable="true">
            <Toast/>
            <div class="p-fluid">
				<br>				
                <div class="p-field p-grid">                   
					<div class="p-col-12 p-md-6">
						<label>Nombre</label>
						<InputText v-model="nombre" type="text" autofocus/>
					</div>
					<div class="p-col-12 p-md-6">
						<label>Apellido</label>
						<InputText v-model="apellido" type="text"/>
					</div>
					<div class="p-col-12 p-md-12">
						<label>Correo</label>
						<InputText v-model="correo" type="email"/>
					</div>
					<div class="p-col-12 p-md-6">
						<label>Contraseña</label>
                        <InputText v-model="clave" type="password"/>
					</div>
					<div class="p-col-12 p-md-6">
						<label>Confirmar Contraseña</label>
                        <InputText v-model="claveconf" type="password"/>
					</div>
                    <div class="p-col-12 p-md-12">
						La clave debe tener una longitud mínima de 8, mayúsculas, minísculas y un carácter especial ! @ # $ % ^ & * _ .
					</div>
                </div>	
			</div>	
            <template #footer>
                <Button label="Aceptar" icon="pi pi-check" class="p-button-secondary" @click="Register" autofocus/>
                <Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="close"/>
            </template>
        </Dialog>
	</div>
</template>

<script>
import API from "../service/API";
import Crypto from "crypto-js";

export default {
    props: {
		display: {
			type: Boolean,
			default: false,
		}
	},
    data() {
        return {
            displayRegister: false,
            correo: null,
            clave: null,
			nombre: null,
			apellido: null,
            claveconf: null,
            size: '40vw',
		}
	},
    created() {      
        if (this.$store.state.mobile){this.size = '80vw';}
    },
    methods: {
        limpiar() {
            this.clave = null;
            this.claveconf = null;
        },
        close() {
            this.displayRegister = false;
            this.limpiar();
            this.$emit('close-register', event);
        },
        Register() {
            if (this.nombre == null) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Nombre', life: 10000});
            } else if (this.apellido == null) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Apellido', life: 10000});
            } else if (this.correo == null) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Correo', life: 10000});
            } else if (this.clave == null) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Contraseña', life: 10000});
            } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.
                test(this.correo)) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Correo', life: 10000});
            } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[_.!@#$%^&*])(?=.{8,})/.test(this.clave)) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Contraseña', life: 10000});
            } else if (this.clave != this.claveconf) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Confirmación', life: 10000});
            } else {
                this.$store.commit('Loading');
                const cifrado = Crypto.HmacSHA1(this.clave, "btcoin").toString();
                const Consulta = new API();
                Consulta.Procesar('Registrar',{
                    nombre: this.nombre,
                    apellido: this.apellido,
                    correo: this.correo,
                    clave: cifrado
                }).then(response => {
                    //this.$store.state.error = response;	
                    if(response.error){
                        this.$toast.add({severity:'error', summary: 'Mensaje', detail: response.mensaje, life: 10000});
                        this.$store.commit('Loading');
                    } else {
                        this.$toast.add({severity:'success', summary: 'Mensaje', detail: response.mensaje, life: 10000});
                        this.$store.commit('Loading');
                        this.close();
                    }
                    
                });
                this.limpiar();
            } 
        },
	},
    watch: {
		display(newValue) {
			this.displayRegister = newValue;
		}
	},


}
</script>