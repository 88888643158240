<template>
	<div>
        <Dialog header="Cambiar Clave" v-model:visible="displayClave" @update:visible="close" :style="{width: size}" :modal="true" :maximizable="true" style="background: #727376;position: absolute;">
            <Toast/>
            <div class="p-fluid">
				<br>				
                <div class="p-field p-grid">
                    <div class="p-col-7 p-md-7">
                        <label>Contraseña Actual</label>
                        <InputText v-model="claveactual" type="password" autofocus/>
                    </div>
                </div>	
                <div class="p-field p-grid">
					<div class="p-col-12 p-md-6">
						<label>Contraseña Nueva</label>
                        <InputText v-model="clave" type="password"/>
					</div>
					<div class="p-col-12 p-md-6">
						<label>Confirmar Contraseña</label>
                        <InputText v-model="claveconf" type="password"/>
					</div>
                    <div class="p-col-12 p-md-12">
						La clave debe tener una longitud mínima de 8, mayúsculas, minísculas, números y un carácter especial ! @ # $ % ^ & * _ .
					</div>
                </div>
			</div>	
            <template #footer>
                <Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="Reset"/>
                <Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="close"/>
            </template>
        </Dialog>
	</div>
</template>

<script>
import API from "../service/API";
import Crypto from "crypto-js";

export default {
    props: {
		display: {
			type: Boolean,
			default: false,
		}
	},
    data() {
        return {
			displayClave: false,
            correo: null,
            claveactual: null,
            clave: null,
            claveconf: null,
            validar: false,
            codigo: null,
            codigosend: null,
            size: '40vw',

            visibleFull: false
		}
	},
    created() {      
        if (this.$store.state.mobile){this.size = '80vw';}
    },
    methods: {
        limpiar() {
            this.claveactual = null;
            this.clave = null;
            this.claveconf = null;
            this.validar = false;
            this.codigo = null;
            this.codigosend = null;
        },
        close() {
            this.displayClave = false;
            this.limpiar();
            this.$emit('close-clave', event);
        },
        Reset() {
            if (this.claveactual == null || this.clave == null) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Contraseña', life: 10000});
            } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[_.!@#$%^&*])(?=.{8,})/.test(this.clave)) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Contraseña Nueva', life: 10000});
            } else if (this.clave != this.claveconf) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Confirmación', life: 10000});
            } else {
                this.$store.commit('Loading');
                const cifrado1 = Crypto.HmacSHA1(this.claveactual, "btcoin").toString();
                const cifrado2 = Crypto.HmacSHA1(this.clave, "btcoin").toString();
                const Consulta = new API('Seguridad');
                Consulta.Procesar('CambioClave',{
                    claveactual: cifrado1,
                    clavenueva: cifrado2
                }).then(response => {
                    //this.$store.state.error = response;	
                    if(response.error){
                        this.$toast.add({severity:'error', summary: 'Mensaje', detail: response.mensaje, life: 10000});
                    } else {
                        this.$toast.add({severity:'success', summary: 'Mensaje', detail: response.mensaje, life: 10000});
                        this.close();
                        this.displayClave = true; 
                    }
                    this.$store.commit('Loading');
                });
            } 
        },
        onMenuToggle(event) {
            this.$emit('close-clave', event);
        },	
	},
    watch: {
		display(newValue) {
			this.displayClave = newValue;
		}
	},


}
</script>