import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'Inicio',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Home.vue')
    },  
    {
        path: "/buscar/:cond/:id",
        name: 'Buscar',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Buscar.vue')
    },
    {
        path: "/catalago/:cond/:id",
        name: 'Catalago',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Catalago.vue')
    },
    {
        path: "/dinamica",
        name: 'Dinamica',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Dinamica.vue')
    },
    {
        path: "/empresa",
        name: 'Empresa',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Empresa.vue')
    },
    {
        path: "/formapago",
        name: 'Forma de Pago',
        component: () => import(/* webpackChunkName: "Home" */ './pages/FormaPago.vue')
    },
    {
        path: "/carrito",
        name: 'Carrito',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Carrito.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/clientes",
        name: 'Clientes',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Clientes.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/proveedor",
        name: 'Proveedor',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Proveedor.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/tipocliente",
        name: 'TipoCliente',
        component: () => import(/* webpackChunkName: "Home" */ './pages/TipoCliente.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/empresas",
        name: 'Empresas',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Empresas.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/producto",
        name: 'Producto',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Producto.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/costo",
        name: 'Costo',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Costo.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/pedido",
        name: 'Pedido',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Pedido.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/pedidos",
        name: 'Pedidos',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Pedidos.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/servicio",
        name: 'Servicio',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Servicio.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/servicios",
        name: 'Servicios',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Servicios.vue'),
        meta: { authRequired: true }
    },

    
    {
        path: "/tiposervicio",
        name: 'Tipo Servicio',
        component: () => import(/* webpackChunkName: "Home" */ './pages/TipoServicio.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/compras",
        name: 'Compras',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Compras.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/facturacion",
        name: 'Facturacion',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Facturacion.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/nota",
        name: 'Nota',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Nota.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/cotizaciones",
        name: 'Cotizaciones',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Cotizaciones.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/usuarios",
        name: 'Usuarios',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Usuarios.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/roles",
        name: 'Roles',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Roles.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/inventario",
        name: 'Inventario',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Inventario.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/cuentapagar",
        name: 'CuentaPagar',
        component: () => import(/* webpackChunkName: "Home" */ './pages/CuentaPagar.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/cuentacobrar",
        name: 'CuentaCobrar',
        component: () => import(/* webpackChunkName: "Home" */ './pages/CuentaCobrar.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/intercambio",
        name: 'Intercambio',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Intercambio.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/contabilidad",
        name: 'Contabilidad',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Contabilidad.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/aportes",
        name: 'Aportes',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Aportes.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/dashboard",
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Dashboard.vue'),
        meta: { authRequired: true }
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
